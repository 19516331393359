.navbar {
  margin-bottom: 2rem;
}

@media (max-width: 767px) {
  .navbar {
    margin-bottom: 1rem;
  }
}

.logo {
  height: 54px;
}

@media (max-width: 767px) {
  .logo {
    height: 36px;
  }
}
