.cinza {
  color: #6c757d;
}

.projecto {
  display: flex;
  justify-content: space-between;
  align-items: stretch;
  align-content: center;
}

.nomeProjecto label {
  font-weight: 500;
}

.accao {
  display: flex;
  margin: auto 10px;
}

.nomeProjecto {
  display: flex;
  flex: 1;
  margin: auto 10px;
}

.datas {
  display: flex;
  flex: 1;
  margin: auto 10px;
  justify-content: center;
}

.estadoButton {
  display: flex;
  margin: auto 10px;
}

@media only screen and (max-width: 767px) {
  .accao {
    order: 3;
    margin: 0 !important;
    padding: 0 0 0 5px !important;
    justify-content: flex-start;
  }

  .nomeProjecto {
    order: 1;
    flex-basis: 60%;
    margin: 0 0 5px 0 !important;
    padding: 0 !important;
    justify-content: flex-start;
    align-items: center;
  }

  .nomeProjecto label {
    padding-left: 5px !important;
  }

  .datas {
    order: 4;
    margin: 0 !important;
    padding: 0 !important;
    justify-content: flex-end;
  }

  .estadoButton {
    order: 2;
    flex-basis: 40%;
    margin: 0 0 5px 0 !important;
    padding: 0 !important;
    justify-content: flex-end;
  }
}
