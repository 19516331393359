.footer {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin-top: 3rem;
  margin-bottom: 1rem;
}

.footerLogo {
  height: 24px;
}

.contacto {
  border: 1px solid #505050;
  color: #505050;
  cursor: pointer;
  display: inline-block;
  padding: 0.25rem 0.6rem;
  text-align: center;
  user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  -webkit-user-select: none;
  vertical-align: middle;
  white-space: nowrap;
  text-decoration: none;
}

.contacto:focus {
  outline: none;
}

.contacto:hover {
  color: #505050;
  text-decoration: none;
}
