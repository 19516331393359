.escolhaCliente {
  width: 100%;
  display: flex !important;
  flex-direction: row;
  justify-content: space-between;
  align-items: stretch;
}

.clienteSelect {
  width: 100%;
}

.adicionarClienteErro {
  text-align: center;
  color: red;
  margin-top: 1rem;
}

.adicionarClienteButton {
  margin-top: 2rem;
}
