.arquivados {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
}

.arquivados label {
  margin: 0px 0px 0px 5px;
}

.mediumText,
.shortText {
  display: none;
}

@media (max-width: 767px) {
  .fullText {
    display: none;
  }
  .mediumText {
    display: inline;
  }
}

@media (max-width: 355px) {
  .mediumText {
    display: none;
  }
  .shortText {
    display: inline;
  }
}
