.header {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  align-content: center;
  margin-bottom: 1rem;
}

.msgConsultar {
  text-decoration: underline solid #ffd204;
  text-align: center;
}

.header h1 {
  order: 1;
  font-size: 1.85rem;
  text-decoration: underline solid #ffd204;
  margin-bottom: 0.75rem;
  margin-right: 25px;
}

.filtroCliente {
  order: 2;
  margin-bottom: 0.75rem;
  margin-right: 15px;
}

.mostrarArquivados {
  order: 3;
  margin-bottom: 0.75rem;
}

.adicionarProjecto {
  flex: 1;
  order: 4;
  text-align: right;
  margin-bottom: 0.75rem;
}

.adicionarProjecto button {
  width: auto;
}

@media (max-width: 767px) {
  .header {
    margin-bottom: 0.25rem;
  }
  .header h1 {
    display: none;
  }
}

@media (max-width: 520px) {
  .filtroCliente {
    order: 2;
  }

  .mostrarArquivados {
    order: 3;
  }

  .adicionarProjecto {
    order: 1;
    flex: 1 1 100%;
  }

  .adicionarProjecto button {
    width: 100%;
  }
}
