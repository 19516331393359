.nomeCliente {
  text-decoration: underline solid #ffd204;
}

@media (max-width: 767px) {
  .listContainer {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }
}
