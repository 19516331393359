.registrarButton {
  margin-top: 2rem;
}

.tipoConta {
  background-color: white;
  border-radius: 5px;
  outline: none;
  width: 100%;
}
