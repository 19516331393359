.filtro {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: stretch;
  align-content: flex-start;
  flex-wrap: nowrap;
}

.caixaFiltro {
  width: 150px;
  display: flex !important;
  flex-direction: row;
  justify-content: space-between;
}
