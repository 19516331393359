* {
  box-sizing: border-box;
}

.App {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

.corpo {
  flex-grow: 1;
}

.remove-btn {
  margin-right: 0.5rem;
}

.fade-enter {
  opacity: 0.01;
}

.fade-enter-active {
  opacity: 1;
  transition: opacity 1000ms ease-in;
}

.fade-exit {
  opacity: 1;
}

.fade-exit-active {
  opacity: 0.01;
  transition: opacity 1000ms ease-in;
}

.btn.focus,
.btn:focus {
  box-shadow: none;
}
