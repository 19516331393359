#listHeader {
  font-weight: 600;
  color: #505050;
}

#listHeader label {
  text-decoration: underline solid #ffd204;
}

.accaoHeader {
  flex-basis: 70.25px;
  margin: auto 10px;
}

.projectoHeader {
  flex: 1;
  margin: auto 10px;
}

.datasHeader {
  flex: 1;
  margin: auto 10px;
  text-align: center;
}

.estadoHeader {
  flex-basis: 96px;
  margin: auto 10px;
  text-align: right;
}

@media only screen and (max-width: 767px) {
  #listHeader label {
    display: none;
  }
  #listHeader {
    border-top-width: 0;
    border-right-width: 0;
    border-left-width: 0;
    padding: 0;
    height: 0;
  }
}
